import { nanoid } from 'nanoid';
import create from 'zustand';

export enum BannerPriority {
  info = 'info',
  success = 'success',
  warning = 'warning',
  error = 'error',
}

export enum ToastPriority {
  short = 'short',
  long = 'long',
}

export type BannerType = {
  id: string;
  message?: string;
  priority: BannerPriority;
  title: string;
};

export type ToastType = {
  id: string;
  message?: string;
  priority: ToastPriority;
  title: string;
};

type NotificationsState = {
  banners: BannerType[];
  toasts: ToastType[];
  addBanner: (banner: Omit<BannerType, 'id'>) => void;
  addToast: (
    toast: Omit<ToastType, 'id' | 'priority'> & Partial<Pick<ToastType, 'priority'>>
  ) => void;
  dismiss: (id: string) => void;
};

const SHORT_TIMEOUT = 3500;
const LONG_TIMEOUT = 6000;
/*
 * The notification store contains banners and toasts.
 * Banners have a priority and must be manually dismissed.
 * Toasts are short-term alerts that automatically dismiss.
 */
export const useNotificationStore = create<NotificationsState>((set) => ({
  banners: [],
  toasts: [],
  addBanner: (newBanner) => {
    const banner: BannerType = {
      ...newBanner,
      id: nanoid(),
    };
    set(
      (state) => ({
        banners: [...state.banners, banner],
      }),
      undefined
    );
  },
  addToast: (newToast) => {
    const toast: ToastType = {
      ...newToast,
      priority: newToast.priority ?? ToastPriority.short,
      id: nanoid(),
    };
    set(
      (state) => ({
        toasts: [...state.toasts, toast],
      }),
      undefined
    );
    setTimeout(
      () =>
        set(
          (state) => ({
            toasts: state.toasts.filter((t) => t.id !== toast.id),
          }),
          undefined
        ),
      toast.priority === 'long' ? LONG_TIMEOUT : SHORT_TIMEOUT
    );
  },
  dismiss: (id) =>
    set(
      (state) => ({
        toasts: state.toasts.filter((notification) => notification.id !== id),
        banners: state.banners.filter((notification) => notification.id !== id),
      }),
      undefined
    ),
}));
