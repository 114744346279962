import { Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/solid';
import { Fragment } from 'react';

import { ToastType } from '@/hooks/useNotificationStore';

export type ToastProps = {
  toast: ToastType;
  onDismiss: (id: string) => void;
};

/*
 * A Toast is short-term alert.  It is fixed to the bottom right of the viewport.
 * The NotificationService automatically dismisses them.
 */
export const Toast: React.FC<ToastProps> = ({ toast: { id, message, title }, onDismiss }) => {
  return (
    <div className="fixed z-50 bottom-0 right-0 pb-3 pr-3" aria-live="assertive">
      <Transition
        show={true}
        as={Fragment}
        enter="transform ease-out duration-300 transition"
        enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        enterTo="translate-y-0 opacity-100 sm:translate-x-0"
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div>
          <div
            className="flex items-top justify-between max-w-md py-2 px-3 rounded-lg shadow-sm mx-auto border
            bg-dojodarkgray text-white border-dojomist"
            role="alert"
            aria-label={title}
          >
            <div className="px-2 pt-0.5">
              <p className="font-bold">{title}</p>
              {message ? <p>{message}</p> : null}
            </div>
            <div className="flex-shrink-0">
              <button
                className="rounded-lg rounded-lg inline-flex focus:outline-none focus:ring-1 focus:ring-offset-1 inline-flex hover:text-gray-400"
                onClick={() => {
                  onDismiss(id);
                }}
              >
                <span className="sr-only">Close</span>
                <XIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </Transition>
    </div>
  );
};
