import { QueryKey, useQuery, UseQueryResult } from 'react-query';

import { getRepoBranches, GetRepoBranchOptions } from '@/api';
import { QueryConfig } from '@/lib/react-query';
import { RepoBranch } from '@/types';

type useRepoBranchesOptions = GetRepoBranchOptions & {
  config?: QueryConfig<typeof getRepoBranches>;
};

export const useRepoBranches = ({
  config,
  repoId,
  sourceType,
  defaultBranchOnly,
  createdAt,
  fields,
}: useRepoBranchesOptions): UseQueryResult<RepoBranch[]> => {
  return useQuery({
    ...config,
    queryKey: [
      'repos',
      repoId,
      sourceType,
      defaultBranchOnly,
      createdAt,
      fields,
      'branches',
    ] as QueryKey,
    queryFn: () => getRepoBranches({ repoId, sourceType, defaultBranchOnly, createdAt, fields }),
    staleTime: 1200000,
  });
};
