import { DateTime } from 'luxon';

import { CI_PROVIDERS } from '@/types';

export const formatDate = (date: string): string => {
  // return dayjs(date).format("MMMM D, YYYY h:mm A");

  // https://github.com/moment/luxon/blob/master/docs/formatting.md
  return DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_SHORT);
};

export const formatGitHash = (value: string): string => {
  // https://git-scm.com/book/en/v2/Git-Tools-Revision-Selection
  if (!value) {
    return value;
  }

  return value.substring(0, 7);
};

export const convertToGitHttps = (url: string): string => {
  if (url.startsWith('git')) {
    // Replace ssh protocol with https protocol
    return url.replace(/^git@([^:/]+):/, 'https://$1/').replace(/\.git$/, '');
  } else {
    return url.replace(/^https?:\/\/[^@]+@/, 'https://').replace(/\.git$/, '');
  }
};

export const getMergeRequestLink = (
  url: string,
  ciProvider?: CI_PROVIDERS,
  branch?: string
): string => {
  let link = convertToGitHttps(url);
  if (ciProvider === CI_PROVIDERS.gitlab) {
    link += `/-/merge_requests`;
    if (branch) {
      link += `?scope=all&state=opened&target_branch=${branch}`;
    }
  } else if (ciProvider === CI_PROVIDERS.bitbucket) {
    link += '/pull-requests';
    if (branch) {
      link += `/?state=OPEN&at=${branch}`;
    }
  } else if (ciProvider === CI_PROVIDERS.github) {
    link += '/pulls';
    if (branch) {
      link += `/?q=base:${branch}`;
    }
  }

  return link;
};

export const getBranchesPageLink = (url: string, ciProvider?: CI_PROVIDERS): string => {
  let link = convertToGitHttps(url);
  if (ciProvider === CI_PROVIDERS.gitlab) {
    link += `/-/branches`;
  } else if (ciProvider === CI_PROVIDERS.bitbucket) {
    link += '/branches/?status=all';
  } else if (ciProvider === CI_PROVIDERS.github) {
    link += '/branches';
  }

  return link;
};

export const getBranchLink = (
  url: string,
  ciProvider: CI_PROVIDERS | undefined,
  branch: string
): string => {
  let link = convertToGitHttps(url);
  if (ciProvider === CI_PROVIDERS.gitlab) {
    link += `/-/tree/${branch}`;
  } else if (ciProvider === CI_PROVIDERS.bitbucket) {
    link += `/branch/${branch}`;
  } else if (ciProvider === CI_PROVIDERS.github) {
    link += `/tree/${branch}`;
  }

  return link;
};

export const getCommitLink = (
  url: string,
  ciProvider?: CI_PROVIDERS,
  commitId?: string
): string => {
  let link = convertToGitHttps(url);
  if (ciProvider === CI_PROVIDERS.gitlab) {
    link += `/-/commit/${commitId}`;
  } else if (ciProvider === CI_PROVIDERS.bitbucket) {
    link += `/commits/${commitId}`;
  } else if (ciProvider === CI_PROVIDERS.github) {
    link += `/commit/${commitId}`;
  }

  return link;
};

export type QueryParamValue = string | number | boolean | undefined | null;

export const getDeepLink = (baseLink: string, params?: Record<string, QueryParamValue>): string => {
  const queryParams = new URLSearchParams(params as Record<string, string>).toString();

  if (!queryParams) {
    return baseLink;
  }
  return `${baseLink}?${queryParams}`;
};

export const getTimeAgoString = (date: string): string => {
  const created = DateTime.fromISO(date);
  const diff = DateTime.now().diff(created, [
    'years',
    'months',
    'days',
    'hours',
    'minutes',
    'seconds',
  ]);

  if (diff.years >= 1) {
    return 'Over a year ago';
  } else if (diff.months >= 2) {
    return `${diff.months} months ago`;
  } else if (diff.months === 1) {
    return 'One month ago';
  } else if (diff.days >= 2) {
    return `${diff.days} days ago`;
  } else if (diff.days === 1) {
    return `1 day ago`;
  } else if (diff.hours >= 2) {
    return `${diff.hours} hours ago`;
  } else if (diff.hours === 1) {
    return '1 hour ago';
  } else if (diff.minutes >= 5) {
    return `${diff.minutes} minutes ago`;
  }

  return 'Just now';
};
