import { FormIcon, SearchIcon, CodeIcon } from 'evergreen-ui';

import { CiRunStatus, CiRunType, STAGE } from '@/types';

import { PipelineStatusItem } from './PipelineStatusItem';

export type PipelineStatusType = {
  status: CiRunStatus;
  type: CiRunType;
};

interface Props {
  statusList: PipelineStatusType[];
}

const stageTypeMap: Record<STAGE, string> = {
  [STAGE.TEST]: 'test',
  [STAGE.QUALITY]: 'quality',
  [STAGE.BUILD]: 'build',
};

// This component will show pipeline status for each of these stages - Test, Quality, and Build
export const PipelineStatus: React.FC<Props> = ({ statusList }) => {
  const testStatusList = statusList.filter((s) => s.type === stageTypeMap[STAGE.TEST]);
  const qualityStatusList = statusList.filter((s) => s.type === stageTypeMap[STAGE.QUALITY]);
  const buildStatusList = statusList.filter((s) => s.type === stageTypeMap[STAGE.BUILD]);

  let testStatus = CiRunStatus.success;
  if (testStatusList.some((s) => s.status === CiRunStatus.failure)) {
    testStatus = CiRunStatus.failure;
  }

  let qualityStatus = CiRunStatus.success;
  if (qualityStatusList.some((s) => s.status === CiRunStatus.failure)) {
    qualityStatus = CiRunStatus.failure;
  }

  let buildStatus = CiRunStatus.success;
  if (buildStatusList.some((s) => s.status === CiRunStatus.failure)) {
    buildStatus = CiRunStatus.failure;
  }

  return (
    <div className="text-neutral-700 flex gap-5">
      {!!testStatusList.length && (
        <PipelineStatusItem status={testStatus} Icon={FormIcon} stage={STAGE.TEST} />
      )}
      {!!qualityStatusList.length && (
        <PipelineStatusItem status={qualityStatus} Icon={SearchIcon} stage={STAGE.QUALITY} />
      )}
      {!!buildStatusList.length && (
        <PipelineStatusItem status={buildStatus} Icon={CodeIcon} stage={STAGE.BUILD} />
      )}
    </div>
  );
};
