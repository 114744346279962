import * as React from 'react';
import { Tooltip } from 'react-tooltip';

interface EOTooltipProps {
  children?: React.ReactElement;
  className?: string;
  label?: string;
}

export const EOTooltip: React.FC<EOTooltipProps> = ({ className, label, ...props }) => {
  const tooltipID = self.crypto.randomUUID();

  return (
    <div
      className={className}
      data-tooltip-id={tooltipID}
      data-tooltip-content={label}
      data-tooltip-delay-show={0}
    >
      {props.children || null}
      <Tooltip id={tooltipID} />
    </div>
  );
};
