import { ShareIcon } from 'evergreen-ui';
import * as React from 'react';

import './ShareLink.css';

export type ShareLinkProps = {
  label: string;
  link?: string | null;
};

export const ShareLink: React.FC<ShareLinkProps> = ({ label, link }) => {
  if (!link) {
    return null;
  }

  return (
    <a
      target="_blank"
      rel="noreferrer"
      className="inline-flex items-center text-dojoyellow"
      href={link}
    >
      {label}
      <ShareIcon className="ml-1 sharelink-icon" />
    </a>
  );
};
