import cx from 'classnames';
import { IconComponent, Tooltip } from 'evergreen-ui';

import { CiRunStatus, STAGE } from '@/types';

interface Props {
  status: CiRunStatus;
  Icon: IconComponent;
  stage: STAGE;
}

export const PipelineStatusItem: React.FC<Props> = ({ status, Icon, stage }) => {
  return (
    <Tooltip content={`${stage} status`}>
      <div
        data-testid={stage}
        className={cx('flex items-center gap-2', {
          'text-red-400': status === CiRunStatus.failure,
          'text-green-600': status === CiRunStatus.success,
        })}
      >
        {<Icon />}
        <span>{status === CiRunStatus.failure ? 'Fail' : 'Pass'}</span>
      </div>
    </Tooltip>
  );
};
