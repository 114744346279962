import cx from 'classnames';
import { CaretDownIcon, GitBranchIcon, Pane, Popover, SearchInput, TagIcon } from 'evergreen-ui';
import { DateTime } from 'luxon';
import * as React from 'react';

import { useRepoBranches } from '@/hooks/useRepoBranches';
import { CiRunSourceType, Repo } from '@/types';

import { Button } from '../Button';
import { TabSelector } from '../Tabs';

interface BranchSelectProps {
  repo: Repo;
  initialValue: string;
  placeholder?: string;
  testId?: string;
  label?: string;
  disabled?: boolean;
  className?: string;
  onSelect: (value: string) => void;
}

type TabItem = {
  text: string;
  value: CiRunSourceType;
};

const tabs: TabItem[] = [
  { text: 'Branches', value: 'branch' },
  { text: 'Tags', value: 'tag' },
];

export const BranchSelect: React.FC<BranchSelectProps> = ({
  repo,
  initialValue,
  label,
  testId,
  placeholder,
  className,
  disabled,
  onSelect,
}) => {
  const [searchQuery, setSearchQuery] = React.useState('');
  const [tab, setTab] = React.useState<CiRunSourceType>();
  const [branch, setBranch] = React.useState<string>(initialValue);
  const branchQuery = useRepoBranches({
    repoId: repo.id,
    createdAt: {
      begin: DateTime.now().startOf('day').minus({ months: 3 }).toISO(),
    },
    fields: ['branch', 'sourceType'],
    sourceType: tab,
  });
  const popoverRef = React.useRef<any>(null);

  const isBranch = React.useMemo(() => {
    if (!branchQuery.data) {
      return true;
    }

    const item = branchQuery.data.find((b) => b.branch === branch);
    return item?.sourceType === 'branch';
  }, [branch, branchQuery.data]);

  const branches = React.useMemo(() => {
    if (!branchQuery.data) {
      return [];
    }
    return branchQuery.data.filter(
      (d) => d.sourceType === tab && d.branch.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [tab, searchQuery, branchQuery.data]);

  React.useEffect(() => {
    if (!branchQuery.data?.length) {
      return;
    }
    if (!branch) {
      setBranch(branchQuery.data[0].branch);
      onSelect(branchQuery.data[0].branch);
    } else if (!tab) {
      const item = branchQuery.data.find((b) => b.branch === branch);
      if (item && item.sourceType !== tab) {
        setTab(item.sourceType);
      }
    }
  }, [branch, tab, branchQuery.data, onSelect]);

  return (
    <div data-testid={testId}>
      {label ? <label className={cx('block font-medium', className)}>{label}</label> : null}
      <Popover
        // eslint-disable and ts-ignore are required because library type definition is not updated
        // eslint-disable-next-line
        // @ts-ignore
        ref={popoverRef}
        bringFocusInside
        content={
          <Pane
            width={288}
            height={'auth'}
            padding={20}
            display="flex"
            flexDirection="column"
            gap={10}
          >
            <SearchInput
              placeholder="Filter branches/tags"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchQuery(e.target.value)}
              value={searchQuery}
              height={40}
              width={248}
              autoFocus
            />

            <div className="flex border-b border-grey-300">
              {tabs.map((item) => (
                <TabSelector
                  key={item.value}
                  narrow
                  isActive={tab === item.value}
                  onClick={() => setTab(item.value)}
                >
                  {item.text}
                </TabSelector>
              ))}
            </div>

            <div className="max-h-96 overflow-auto">
              {branches.map((item) => (
                <span
                  className={cx(
                    'block px-3 py-1 font-medium leading-6 border-b border-grey-300 cursor-pointer truncate',
                    branch === item.branch && 'text-dojoyellow'
                  )}
                  key={item.branch}
                  role="button"
                  tabIndex={0}
                  onKeyDown={() => {}}
                  onClick={() => {
                    popoverRef.current?.close();
                    setBranch(item.branch);
                    onSelect(item.branch);
                  }}
                >
                  {item.branch}
                </span>
              ))}
            </div>
          </Pane>
        }
      >
        <Button variant="secondary" className={cx('w-72 ml-0', className)} disabled={disabled}>
          <span className="flex w-full items-center justify-between text-neutral-600">
            <span className="flex truncate shrink items-center gap-2">
              {isBranch ? <GitBranchIcon /> : <TagIcon />}
              <span className="shrink	truncate font-normal">{branch ?? placeholder}</span>
            </span>
            <span className="w-5 h-5 shrink-0 bg-neutral-100 rounded flex items-center justify-center">
              <CaretDownIcon />
            </span>
          </span>
        </Button>
      </Popover>
    </div>
  );
};
