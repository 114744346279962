import cx from 'classnames';
import * as React from 'react';

import { Spinner } from '@/components/Elements';

type TableColumn<Entry> = {
  title: string;
  field: keyof Entry;
  className?: string;
  Cell?({ entry }: { entry: Entry }): React.ReactElement;
};

// tailwind class name for table cell vertical alignment
// https://tailwindcss.com/docs/vertical-align
type verticalAlign =
  | 'align-baseline'
  | 'align-top'
  | 'align-middle'
  | 'align-bottom'
  | 'align-text-top'
  | ' align-text-bottom'
  | 'align-sub'
  | 'align-super';

export type Subhead = {
  type: 'subhead';
  title: string;
};

export type TableProps<Entry> = {
  data: Array<Entry | Subhead>;
  columns: TableColumn<Entry>[];
  textWhenEmpty?: string;
  rowAlign?: verticalAlign;
  loading?: boolean;
};

function isSubhead(entry: any): entry is Subhead {
  return entry?.type === 'subhead';
}

export const Table = <Entry extends { id: string }>({
  data,
  columns,
  textWhenEmpty,
  rowAlign,
  loading,
}: TableProps<Entry>): JSX.Element => {
  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border border-neutral-300 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200" role="table">
              <thead className="bg-neutral-200">
                <tr>
                  {columns.map((column, index) => (
                    <th
                      key={`${column.title}${index}`}
                      scope="col"
                      className="px-6 py-3 text-left text-sm font-bold text-neutral-700 uppercase tracking-wider"
                    >
                      {column.title}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan={columns.length}>
                      <div className="text-gray-500 h-44 flex justify-center items-center flex-col">
                        <Spinner size="lg" />
                      </div>
                    </td>
                  </tr>
                ) : data.length ? (
                  <>
                    {data.map((entry) => (
                      <React.Fragment key={'id' in entry ? entry.id : entry.title}>
                        {isSubhead(entry) ? (
                          <tr className={cx('bg-gray-50 border-t border-neutral-300', rowAlign)}>
                            <td
                              colSpan={columns.length}
                              className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-500 border-gray-200 border-solid border-b"
                            >
                              {entry.title}
                            </td>
                          </tr>
                        ) : (
                          <tr
                            className={cx(
                              'bg-white border-t border-neutral-300  hover:bg-neutral-150',
                              rowAlign
                            )}
                            data-testid={entry.id}
                          >
                            {columns.map(({ Cell, field, title, className }, columnIndex) => (
                              <td
                                key={`${title}${columnIndex}`}
                                className={cx(
                                  'px-6 py-4 whitespace-nowrap font-medium',
                                  columnIndex ? 'text-neutral-700' : 'text-gray-900',
                                  className
                                )}
                              >
                                {Cell ? <Cell entry={entry} /> : entry[field]}
                              </td>
                            ))}
                          </tr>
                        )}
                      </React.Fragment>
                    ))}
                  </>
                ) : (
                  <tr>
                    <td colSpan={columns.length}>
                      <div className="text-gray-500 h-44 flex justify-center items-center flex-col">
                        {textWhenEmpty || 'No Entries Found'}
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
