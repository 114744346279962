import { CheckIcon } from '@heroicons/react/solid';
import { OptionsListProps, SelectMenuOption } from 'evergreen-ui';

import { EOSelectMenu } from '../EOSelectMenu';

const MIN_WIDTH = 216;

type CustomOptionProps = {
  key: SelectMenuOption['value'];
  label: SelectMenuOption['label'];
  item: SelectMenuOption;
  style: object;
  height: NonNullable<OptionsListProps['optionSize']>;
  onSelect: () => void;
  onDeselect: () => void;
  isSelectable: boolean;
  isSelected: boolean;
  disabled: SelectMenuOption['disabled'];
};

const CustomOption = (props: CustomOptionProps): JSX.Element => {
  const { item, label, onSelect, onDeselect, isSelected } = props;
  return (
    <button
      key={item.value}
      style={{ minWidth: MIN_WIDTH }}
      className="px-3 py-1 flex gap-3 items-center w-full border-b border-neutral-400 hover:bg-neutral-100"
      onClick={() => {
        isSelected ? onDeselect() : onSelect();
      }}
    >
      {isSelected ? (
        <div className="w-4 h-4 rounded bg-dojoyellow text-white shrink-0">
          <CheckIcon />
        </div>
      ) : (
        <div className="w-4 h-4 rounded border border-neutral-400 shrink-0"></div>
      )}
      <span className="text-neutral-800">{label}</span>
    </button>
  );
};

type Props = {
  options: string[];
  selected: string[];
  onChange: (value: string[]) => void;
  disabled?: boolean;
};

export const MultiSelect = (props: Props): JSX.Element => {
  const { options, selected, onChange } = props;
  const optionList = options.map((label) => ({
    label,
    value: label,
  }));

  return (
    <div className="flex flex-col eo-select-multi" style={{ width: 216 }}>
      <EOSelectMenu
        isMultiSelect
        width="100%"
        menuWidth="auto"
        minWidth={MIN_WIDTH}
        options={optionList}
        selected={selected}
        disabled={props.disabled}
        closeOnSelect={false}
        itemRenderer={(props) => <CustomOption {...props} />}
        onSelect={(item) => {
          const value = [...selected, `${item.value}`];
          onChange(value);
        }}
        onDeselect={(item) => {
          const value = selected.filter((s) => s !== `${item.value}`);
          onChange(value);
        }}
      />
    </div>
  );
};
