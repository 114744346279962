import { useAuth0 } from '@auth0/auth0-react';
import * as Sentry from '@sentry/react';
import React from 'react';

export const MaintainSentryUser: React.FC = ({ children }) => {
  const { user } = useAuth0();

  React.useEffect(() => {
    if (user) {
      Sentry.setUser({ email: user.email, id: user.sub });
    } else {
      Sentry.configureScope((scope) => scope.setUser(null));
    }
  }, [user]);
  return <>{children}</>;
};
