// tailwindcss does not support 'counter-reset', so the code snippet's line numbers
// are handled using vanilla css
import './CodeSnippet.css';

/*
 * Renders the supplied plaintext in a <pre> block with line numbers
 */
export const CodeSnippet: React.FC<{ plaintext: string }> = ({ plaintext }) => {
  const lines = plaintext.split('\n');
  return (
    <pre className="bg-bluewood rounded-b-lg h-640px overflow-auto text-white code-snippet">
      <code className="">
        {lines.map((line, index) => (
          <span key={index}>{line}</span>
        ))}
      </code>
    </pre>
  );
};
