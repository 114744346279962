import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import * as React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { initMocks } from './test/server';

// Potentially enable api mocking via mock-service-worker (msw).
// It activates http-level mocking when process.env.REACT_APP_API_MOCKING=true
// You'll know if `msw` is enabled in the browser if you see
// `[MSW] Mockikng enabled.` in the browser console log.  If you aren't expecting to see it,
// there is a problem to be fixed!
initMocks();

if (process.env.REACT_APP_SENTRY_ENABLED === 'true') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT || 'dev',
    tracesSampler: (samplingContext) => {
      if (samplingContext.transactionContext.name === 'GET /') {
        return 0;
      }
      return 0.25;
    },
  });
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
