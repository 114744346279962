import { useAuth0 } from '@auth0/auth0-react';
import * as React from 'react';

import { Spinner } from '@/components/Elements';
import { setAuth0AccessToken } from '@/lib/axios';

export const MaintainAuth0AccessToken: React.FC = ({ children }) => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const [isFetching, setIsFetching] = React.useState(true);
  React.useEffect((): void => {
    const setToken = async (): Promise<void> => {
      const token = await getAccessTokenSilently();
      setAuth0AccessToken(token);
      setIsFetching(false);
    };
    if (isAuthenticated) {
      setIsFetching(true);
      void setToken();
    } else {
      setIsFetching(false);
      setAuth0AccessToken('');
    }
  }, [isAuthenticated, getAccessTokenSilently]);
  return <>{isFetching ? <Spinner size="lg" /> : children}</>;
};
