import ErrorImage from '@/assets/error.png';
import { Button } from '@/components/Elements/';

export const ScopedErrorBoundaryMessage: React.FC<{
  resetError?: () => void;
  showErrorImage?: boolean;
  showTryAgain?: boolean;
}> = ({ children, resetError = () => undefined, showErrorImage = true, showTryAgain = false }) => {
  return (
    <div className="border-dashed border-dojoyellow border p-8 text-center text-gray-900">
      {showErrorImage ? (
        <img src={ErrorImage} alt="An error has occurred" className="inline" />
      ) : null}
      <div className="p-2">{children}</div>
      {showTryAgain ? <Button onClick={() => resetError()}>Try Again</Button> : null}
    </div>
  );
};
