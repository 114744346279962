import {
  Components,
  defaultTheme as DefaultEvergreenTheme,
  mergeTheme,
  Theme,
  ThemeProvider,
} from 'evergreen-ui';
import * as React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import { Spinner } from '@/components/Elements';
import { Notifications } from '@/components/Notifications/Notifications';
import Auth0ProviderWithHistory from '@/lib/auth0';
import { BrowserRouterWithGA as Router } from '@/lib/browser-router';
import { queryClient } from '@/lib/react-query';

import { MaintainAuth0AccessToken } from './maintain-auth0';
import { MaintainGAUser } from './MaintainGAUser';
import { MaintainSentryUser } from './MaintainSentryUser';
import { TopErrorBoundary } from './top-error-fallback';

const dojoFiveEvergreenTheme = mergeTheme(DefaultEvergreenTheme, {
  // Ref: https://evergreen.segment.com/introduction/theming
  fontFamilies: {
    ui: "'Nunito', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif'",
    display:
      "'Nunito', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif'",
    mono: "'Monaco', 'Courier New'",
  },
  components: {
    DialogHeader: {
      baseStyle: {
        paddingX: 32,
        paddingTop: 32,
        paddingBottom: 8,
      },
    },
    Button: {
      baseStyle: {
        'border-color': '#d8dae5',

        ':not([disabled]):hover': {
          'border-color': '#8f95b2 !important',
        },
      },
    },
    Input: {
      baseStyle: {
        'font-size': 16,
      },
    },
    Option: {
      baseStyle: {
        'font-size': 16,

        '> div > span > div': {
          'font-size': 16,
        },

        '[aria-current="true"]': {
          'background-color': '#FFEFD2 !important',
        },

        '[aria-current="true"] span': {
          color: '#F5A623',
        },

        '[aria-current="true"]:before': {
          'background-color': '#F5A623',
        },
      },
    },
    MenuItem: {
      baseStyle: {
        'font-size': 16,
        padding: 0,
        margin: 0,
        height: 'auto',

        ':active:before': {
          'background-color': '#F5A623',
        },
      },
    },
    Select: {
      baseStyle: {
        'font-size': 16,
        'border-color': '#d8dae5',

        ':not([disabled]):hover': {
          'border-color': '#8f95b2 !important',
          'background-color': 'transparent !important',
        },
      },
    },
    // Global Dialog styles
    // DialogFooter: {
    //   baseStyle: {
    //   },
    // },
    // DialogBody: {
    //   baseStyle: {
    //   },
    // },
  },
} as Partial<Theme<Components & 'Dialog' & 'DialogHeader'>>);

export const AppProvider: React.FC = ({ children }) => {
  return (
    <React.Suspense
      fallback={
        <div className="h-screen w-screen flex items-center justify-center">
          <Spinner size="xl" />
        </div>
      }
    >
      <TopErrorBoundary showDialog>
        <HelmetProvider>
          <QueryClientProvider client={queryClient}>
            {process.env.NODE_ENV !== 'test' && <ReactQueryDevtools />}
            <Notifications />
            <Auth0ProviderWithHistory
              audience={process.env.REACT_APP_AUTH0_AUDIENCE ?? ''}
              clientId={process.env.REACT_APP_AUTH0_CLIENT_ID ?? ''}
              domain={process.env.REACT_APP_AUTH0_DOMAIN ?? ''}
            >
              <MaintainAuth0AccessToken>
                <MaintainSentryUser>
                  <MaintainGAUser trackingId={process.env.REACT_APP_GA_MEASUREMENT_ID}>
                    <ThemeProvider value={dojoFiveEvergreenTheme}>
                      <Router trackingId={process.env.REACT_APP_GA_MEASUREMENT_ID}>
                        {children}
                      </Router>
                    </ThemeProvider>
                  </MaintainGAUser>
                </MaintainSentryUser>
              </MaintainAuth0AccessToken>
            </Auth0ProviderWithHistory>
          </QueryClientProvider>
        </HelmetProvider>
      </TopErrorBoundary>
    </React.Suspense>
  );
};
