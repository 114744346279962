import { ChevronRightIcon } from 'evergreen-ui';
import * as React from 'react';
import { Link } from 'react-router-dom';

export interface BreadcrumbLink {
  link?: string;
  text: string;
}

interface Props {
  items: BreadcrumbLink[];
}

export const Breadcrumb: React.FC<Props> = ({ items }) => {
  return (
    <div className="flex flex-wrap items-center font-semibold text-neutral-700 text-sm">
      {items.map((item, index) => (
        <React.Fragment key={`${item.link}-${item.text}`}>
          {item.link ? (
            <Link className="hover:underline" to={item.link}>
              {item.text}
            </Link>
          ) : (
            <span className="text-neutral-900">{item.text}</span>
          )}

          {index !== items.length - 1 && <ChevronRightIcon className="mx-1" />}
        </React.Fragment>
      ))}
    </div>
  );
};
