import { AppProvider } from './context';
import { Landing } from './features/misc';

export const App: React.FC = () => {
  return (
    <AppProvider>
      <Landing />
    </AppProvider>
  );
};

export default App;
