import { ClipboardIcon } from '@heroicons/react/outline';

import { useNotificationStore } from '@/hooks/useNotificationStore';
import { copyToClipboard } from '@/utils/text';

/**
 * A simple wrapper that when clicked will copy the children to the clipboard.
 * This element is also tabbable (using tab-key to navigate will focus this element),
 * meaning it's also possible to tab to it and click the 'Enter' key.
 */
export const TextWithCopyButton: React.FC<{ children: string }> = ({ children }) => {
  const { addToast } = useNotificationStore();

  const handleCopy = (): void => {
    copyToClipboard(children);
    addToast({ title: 'Copied' });
  };

  return (
    <button
      className="hover:underline cursor-pointer"
      onClick={handleCopy}
      onKeyPress={(event) => (event.key === 'Enter' ? copyToClipboard(children) : undefined)}
    >
      <div>
        {children}
        <ClipboardIcon className="inline text-gray-400 group-hover:text-gray-300 h-6 w-6 ml-2" />
      </div>
    </button>
  );
};

export const CodeTextWithCopyButton: React.FC<{ children: string }> = ({ children }) => {
  const { addToast } = useNotificationStore();

  return (
    <button
      onClick={() => {
        copyToClipboard(children);
        addToast({ title: 'Copied' });
      }}
      onKeyPress={(event) => (event.key === 'Enter' ? copyToClipboard(children) : undefined)}
    >
      <span className="border no-underline text-neutral-800 border-gray-400 px-4 py-2 bg-gray-50 font-mono text-sm hover:text-yellow-800 active:text-dojoyellow">
        {children}
      </span>
      <span className="ml-4 text-dojoyellow hover:underline active:text-yellow-800">Copy</span>
    </button>
  );
};
