interface Props {
  testId?: string;
  children: React.ReactNode;
}

export const Page: React.FC<Props> = ({ children, testId }) => {
  return (
    <div
      className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 py-8 h-full"
      {...(testId ? { 'data-testid': testId } : {})}
    >
      {children}
    </div>
  );
};
