import { CaretDownIcon, SelectMenu, SelectMenuProps } from 'evergreen-ui';
import * as React from 'react';

import { Button } from '../Button';

const ITEM_HEIGHT = 33;

interface EOSelectMenuProps extends Omit<SelectMenuProps, 'children'> {
  children?: React.ReactElement;
  className?: string;
  label?: string;
  icon?: JSX.Element;
  menuWidth?: string;
  disabled?: boolean;
  selectedComponent?: JSX.Element | null;
}

export const EOSelectMenu: React.FC<EOSelectMenuProps> = ({
  hasFilter = false,
  hasTitle = false,
  closeOnSelect = true,
  className,
  label,
  icon,
  menuWidth,
  disabled,
  selectedComponent,
  ...props
}) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const height = Math.min(
    (props.options?.length ?? 1) * ITEM_HEIGHT + (hasFilter ? ITEM_HEIGHT : 0),
    248
  );
  const [width, setWidth] = React.useState(props.width);

  const selected = Array.isArray(props.selected) ? props.selected : [props.selected];

  const selectLabel = selected
    .map((val) => props.options?.find((o) => o.value === val)?.label ?? val)
    .join(', ');

  React.useEffect(() => {
    // only recalculate width when it's set to "100%"
    if (width === '100%' && ref.current) {
      const rect = ref.current.getBoundingClientRect();
      setWidth(rect.width);
    }
  }, [width]);

  return (
    <div ref={ref} className={className}>
      {label && <div className="mb-1">{label}</div>}
      <SelectMenu
        hasFilter={hasFilter}
        hasTitle={hasTitle}
        closeOnSelect={closeOnSelect}
        {...props}
        width={menuWidth ?? width}
        height={height}
      >
        {props.children || (
          <Button
            variant="secondary"
            className="items-center pl-3 pr-2 mx-0"
            disabled={disabled}
            {...(props.width && { width: props.width })}
          >
            <span className="w-full inline-flex justify-start items-center align-top">
              <div className="flex grow items-center">
                {icon ? <div className="pr-2">{icon}</div> : null}
                <span className="mr-4 text-base leading-snug font-normal text-neutral-800 overflow-hidden text-ellipsis whitespace-nowrap">
                  {selectedComponent ?? selectLabel}
                </span>
              </div>

              <span className="bg-neutral-100 w-5 h-5 inline-flex items-center justify-center rounded">
                <CaretDownIcon className="text-neutral-600" />
              </span>
            </span>
          </Button>
        )}
      </SelectMenu>
    </div>
  );
};
