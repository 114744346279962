import cx from 'classnames';
import { Group } from 'evergreen-ui';
import * as React from 'react';

import { Button } from '../Button';

export type TabsStackedOption = {
  value: string;
  label: string;
};

type Props = {
  options: TabsStackedOption[];
  value: string;
  onChange: (value: string) => void;
};

export const TabsStacked: React.FC<Props> = ({ options, value, onChange }) => {
  return (
    <Group role="tablist">
      {options.map((option) => (
        <Button
          key={option.value}
          aria-selected={value === option.value}
          onClick={() => onChange(option.value)}
          variant={value === option.value ? 'active' : 'secondary'}
          className={cx('mx-0 border border-solid border-neutral-400')}
          role="tab"
          size="sm"
        >
          {option.label}
        </Button>
      ))}
    </Group>
  );
};
