import { useEffect, useRef } from 'react';

/**
 * Ref: https://usehooks.com/usePrevious/
 * usePrevious returns the value that was used in the previous rendering of the component.
 * This may be useful in situations like when you need to compare current value with previous value
 * and do something when it has changed.
 */

export default function usePrevious<T>(value: T): T | undefined {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef<T>();
  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes
  // Return previous value (happens before update in useEffect above)
  return ref.current;
}
