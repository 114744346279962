import * as Sentry from '@sentry/react';

import { Button } from '@/components/Elements';

const ErrorFallback: React.FC = () => {
  return (
    <div
      className="text-red-500 w-screen h-screen flex flex-col justify-center items-center"
      role="alert"
    >
      <h2 className="text-lg font-semibold">Ooops, something went wrong :( </h2>
      <Button className="mt-4" onClick={() => window.location.reload()}>
        Refresh
      </Button>
    </div>
  );
};

export const TopErrorBoundary: React.FC<{ showDialog?: boolean }> = ({
  children,
  showDialog = false,
}) => {
  return (
    <Sentry.ErrorBoundary fallback={<ErrorFallback />} showDialog={showDialog}>
      {children}
    </Sentry.ErrorBoundary>
  );
};
