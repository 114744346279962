import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';

import usePrevious from '@/hooks/usePrevious';

interface Props {
  trackingId?: string;
}

export const MaintainGAUser: React.FC<Props> = ({ trackingId, children }) => {
  const [userEmail, setUserEmail] = React.useState<string | undefined>();
  const prevEmail = usePrevious(userEmail);
  const { user } = useAuth0();

  React.useEffect(() => {
    if (user?.email !== userEmail) {
      setUserEmail(user?.email);
    }
    if (prevEmail !== userEmail) {
      if (!window.gtag || !trackingId) {
        return;
      }

      // You can check how to config user_id here
      // https://developers.google.com/analytics/devguides/collection/gtagjs/cookies-user-id#set_user_id
      window.gtag('config', trackingId, {
        user_id: userEmail,
      });
    }
  }, [userEmail, prevEmail, user?.email, trackingId]);
  return <>{children}</>;
};
