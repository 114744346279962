import { ArrowLeftIcon } from 'evergreen-ui';
import * as React from 'react';
import { Link } from 'react-router-dom';

interface Props {
  link: string;
  text?: string;
}

export const BreadcrumbBack: React.FC<Props> = ({ link, text = 'Back' }) => {
  return (
    <div className="flex flex-wrap pb-5 font-semibold text-neutral-700 text-sm">
      <Link className="hover:underline text-dojoyellow inline-flex items-center" to={link}>
        <ArrowLeftIcon className="mr-1" /> {text}
      </Link>
    </div>
  );
};
