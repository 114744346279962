import { useNotificationStore, BannerType, ToastType } from '@/hooks/useNotificationStore';

import { Banner } from './Banner';
import { Toast } from './Toast';

/*
 * Component that renders the oldest banner (and only the oldest banner).  Per style guidelines,
 * only one banner can be displaed at a time.  (Banners must be manually dismissed).
 */
const OneBanner: React.FC<{ banners: BannerType[]; dismiss: any }> = ({ banners, dismiss }) => {
  if (banners.length === 0) {
    return null;
  }

  return <Banner key={banners[0].id} banner={banners[0]} onDismiss={dismiss} />;
};

/*
 * Component that renders the oldest toast (and only the oldest toast).  Per style guidelines,
 * only one toast can be displayed at a time.  (toasts are automatically removed)
 */
const OneToast: React.FC<{ dismiss: any; toasts: ToastType[] }> = ({ dismiss, toasts }) => {
  if (toasts.length === 0) {
    return null;
  }
  return <Toast key={toasts[0].id} toast={toasts[0]} onDismiss={dismiss} />;
};

/*
 * Widget to display both types of notifications in their fixed zones on the page.
 * Banners: top center
 * Toasts: bottom right.
 */
export const Notifications: React.FC = () => {
  const { banners, dismiss, toasts } = useNotificationStore();

  return (
    <>
      <OneBanner banners={banners} dismiss={dismiss} />
      <OneToast toasts={toasts} dismiss={dismiss} />
    </>
  );
};
