import cx from 'classnames';
import * as React from 'react';

import { Spinner } from '@/components/Elements/Spinner';

const variants = {
  primary: [
    'bg-dojoyellow border-dojoyellow',
    'hover:bg-yellow-300 hover:border-yellow-300',
    'active:bg-yellow-400 active:border-yellow-400',
    'focus:bg-yellow-400 focus:border-yellow-400',
    'disabled:bg-yellow-200 disabled:border-yellow-200',
    'text-white',
  ],
  secondary: [
    'bg-white',
    'border-gray-400',
    'hover:text-neutral-800 hover:border-neutral-600',
    'active:bg-neutral-100',
    'focus:border-neutral-500',
    'disabled:text-neutral-500 disabled:border-neutral-300 disabled:bg-white disabled:bg-neutral-100',
    'text-neutral-700',
  ],
  text: [
    'bg-white border-white',
    'hover:text-neutral-800 hover:bg-neutral-100 hover:border-neutral-100',
    'active:bg-neutral-200 active:border-neutral-200',
    'focus:bg-gray-50 focus:border-gray-50',
    'disabled:text-neutral-500 disabled:bg-white disabled:border-white',
    'text-neutral-700',
  ],
  red_underline_text: ['border-none hover:cursor text-red-400 underline'],
  danger: [
    'bg-red-400 border-red-400',
    'hover:bg-red-500 hover:border-red-500',
    'active:bg-red-600 active:border-red-600',
    'focus:bg-red-500 focus:border-red-500 focus:outline-red-50',
    'disabled:bg-red-50 disabled:border-red-50',
    'text-white',
  ],
  active: [
    'bg-yellow-150 text-dojoyellow',
    'focus:border-yellow-200 focus:outline-yellow-200',
    'hover:bg-yellow-200',
    'active:bg-yellow-200',
  ],
};

const sizes = {
  evergreen: 'py-2 px-4 text-sm leading-none',
  xs: 'py-2 px-2 text-sm',
  sm: 'py-2 px-4 leading-snug',
  md: 'py-2 px-4 text-lg',
  lg: 'py-3 px-8 text-xl',
};

type IconProps =
  | { startIcon: React.ReactElement; endIcon?: never }
  | { endIcon: React.ReactElement; startIcon?: never }
  | { endIcon?: undefined; startIcon?: undefined };

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  inButtonGroup?: boolean;
  isLoading?: boolean;
  size?: keyof typeof sizes;
  srOnlyText?: boolean;
  variant?: keyof typeof variants;
  width?: string | number;
} & IconProps;

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      type = 'button',
      className = '',
      variant = 'primary',
      size = 'md',
      isLoading = false,
      disabled,
      startIcon,
      endIcon,
      srOnlyText = false,
      inButtonGroup = false,
      width,
      ...props
    },
    ref
  ) => {
    return (
      <button
        ref={ref}
        type={type}
        className={cx(
          'inline-flex justify-center items-center align-top',
          'border',
          'focus:outline focus:outline-2 focus:outline-yellow-200',
          'active:outline-none',
          'disabled:cursor-not-allowed',
          'font-bold',
          inButtonGroup ? 'm-0 first:rounded-l last:rounded-r' : 'rounded mx-1',
          ...variants[variant],
          sizes[size],
          className
        )}
        style={{ width }}
        disabled={disabled || isLoading}
        {...props}
      >
        {isLoading && <Spinner size="xs" className="text-current text-white mr-2" />}
        {!isLoading && startIcon}
        <span className={cx(srOnlyText && 'sr-only', 'w-full')}>{props.children}</span>
        {!isLoading && endIcon}
      </button>
    );
  }
);

Button.displayName = 'Button';

Button.defaultProps = {
  size: 'sm',
};
