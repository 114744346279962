import cx from 'classnames';
import { DisableIcon, ErrorIcon, TickCircleIcon } from 'evergreen-ui';

import { CiRunStatus, STAGE } from '@/types';

import { Spinner } from '../Spinner';

interface Props {
  status: CiRunStatus;
  stage: STAGE;
}

export const PipelineStageStatus: React.FC<Props> = ({ status, stage }) => {
  return (
    <div
      data-testid={stage}
      className={cx('flex items-center gap-2', {
        'text-red-400': status === CiRunStatus.failure,
        'text-green-600': status === CiRunStatus.success,
      })}
    >
      {status === CiRunStatus.failure && (
        <>
          <ErrorIcon />
          <span>Failed</span>
        </>
      )}
      {status === CiRunStatus.success && (
        <>
          <TickCircleIcon />
          <span>Successful</span>
        </>
      )}
      {status === CiRunStatus.running && (
        <>
          <Spinner size="sm" />
          <span>In Progress</span>
        </>
      )}
      {status === CiRunStatus.canceled && (
        <>
          <DisableIcon />
          <span>Canceled</span>
        </>
      )}
    </div>
  );
};
