import React, { useLayoutEffect, useRef, useState } from 'react';
import { CategoricalChartState } from 'recharts/types/chart/generateCategoricalChart';

import { DataPoint, EOLineChart, LineChartLegend, tooltipWrapperStyle } from './EOLineChart';

export type MetricDataPoint = DataPoint & {
  pipelineUrl: string;
};

type MetricLineChartProps = {
  dataPoints: MetricDataPoint[];
  legends: LineChartLegend[];
  customToolTip?: JSX.Element;
  xAxisLabel: string;
  yAxisLabel: string;
  yDomainMin?: string | number;
  yDomainMax?: string | number;
  formatYAxisTick?: (value: number) => string;
};

export const MetricLineChart: React.FC<MetricLineChartProps> = (props) => {
  const [chartState, setChartState] = useState<CategoricalChartState>();
  const [width, setWidth] = useState(100);
  const ref = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const rect = ref.current?.getBoundingClientRect();
    if (rect) {
      setWidth(rect.width);
    }
  }, []);

  const handleChartMove = (event: CategoricalChartState): void => {
    setChartState(event);
  };

  const handleChartLeave = (): void => {
    setChartState(undefined);
  };

  return (
    <div ref={ref} style={{ position: 'relative' }} onMouseLeave={handleChartLeave}>
      <EOLineChart {...props} onMouseMove={handleChartMove} />
      {!!chartState?.activePayload?.length && (
        <div
          style={{
            display: 'inline-flex',
            alignItems: 'flex-start',
            position: 'absolute',
            top: chartState.activeCoordinate?.y ?? 0,
            left: chartState.activeCoordinate?.x ?? 0,
            transform: `translate(-${((chartState.activeCoordinate?.x ?? 0) / width) * 100}%, 0)`,
            ...tooltipWrapperStyle,
          }}
        >
          <div>
            {props.customToolTip &&
              chartState &&
              React.cloneElement(props.customToolTip, {
                active: true,
                label: chartState.activeLabel,
                payload: chartState.activePayload,
              })}
            {!!chartState.activePayload?.length && (
              <a
                className="mt-2 text-dojoyellow whitespace-nowrap"
                target="_blank"
                rel="noreferrer"
                href={chartState.activePayload[0].payload.pipelineUrl}
              >
                View in CI System &gt;
              </a>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
