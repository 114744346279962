import { AppState, Auth0Provider, Auth0ProviderOptions } from '@auth0/auth0-react';
import * as React from 'react';

interface IOptions {
  domain: string;
  clientId: string;
  audience: string;
}

/**
 * A wrapper around <Auth0Provider> that preconfigures teh provider and handles redirection.
 */
const Auth0ProviderWithHistory: React.FC<IOptions> = ({ audience, children, clientId, domain }) => {
  const onRedirectCallback: Auth0ProviderOptions['onRedirectCallback'] = function (
    appState: AppState | undefined
  ) {
    window.history.replaceState({}, document.title, appState?.returnTo || window.location.pathname);
  };
  return (
    <Auth0Provider
      audience={audience}
      cacheLocation="localstorage"
      clientId={clientId}
      domain={domain}
      onRedirectCallback={onRedirectCallback}
      redirectUri={window.location.origin}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
