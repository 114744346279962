import cx from 'classnames';
import * as React from 'react';
import { FieldError } from 'react-hook-form';

type FieldWrapperProps = {
  label?: string;
  labelSrOnly?: boolean;
  className?: string;
  error?: FieldError | undefined;
  description?: string;
};

export type FieldWrapperPassThroughProps = Omit<FieldWrapperProps, 'className' | 'children'>;

export const FieldWrapper: React.FC<FieldWrapperProps> = (props) => {
  const { label, labelSrOnly = false, className, error, children } = props;
  return (
    <div>
      <label className={cx('block font-medium', className)}>
        {labelSrOnly ? <span className="sr-only">{label}</span> : label}
        <div className={label}>{children}</div>
      </label>
      {error?.message && (
        <div role="alert" aria-label={error.message} className="font-semibold text-red-500">
          {error.message}
        </div>
      )}
    </div>
  );
};
