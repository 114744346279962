import cx from 'classnames';
import {
  ChevronRightIcon,
  DoubleChevronRightIcon,
  ChevronLeftIcon,
  DoubleChevronLeftIcon,
} from 'evergreen-ui';

import { Button } from '@/components/Elements';

const pageButtonBaseStyle = 'mx-1 py-2 px-4 bg-gray-100';
const unselectedStyle = 'bg-gray-100 hover:bg-neutral-100';
const selectedStyle = 'bg-yellow-100 text-yellow-300 hover:bg-yellow-100';

const pageNumberEllipsis = -1; /* Special page number value to represent an ellipsis */
const pageNumberFirst = 1;
const pageNumberOneEllipsis = 3; /* Page number that transitions to one ellipsis */
const pageNumberTwoEllipsis = 5; /* Page number that transitions to two ellipses */
const maxPageNumbers = 7;

const range = (start: number, stop: number): number[] => {
  const output = [];
  for (let i = start; i <= stop; i++) {
    output.push(i);
  }

  return output;
};

export type PageNumbersProps = {
  pages: number;
  selectedPage: number;
  pageChanged: (page: number) => void;
  maxPageNumbers?: number;
  className?: string;
};

export const PageNumbers: React.FC<PageNumbersProps> = ({
  pages,
  selectedPage,
  pageChanged,
  className = '',
}) => {
  let pageDisplays = [
    pageNumberFirst,
    pageNumberEllipsis,
    ...range(selectedPage, selectedPage + 2),
    pageNumberEllipsis,
    pages,
  ];
  if (pages <= maxPageNumbers) {
    pageDisplays = range(pageNumberFirst, pages);
  } else if (pages > maxPageNumbers && selectedPage <= pageNumberOneEllipsis) {
    pageDisplays = [...range(pageNumberFirst, pageNumberTwoEllipsis), pageNumberEllipsis, pages];
  } else if (pages - selectedPage < pageNumberTwoEllipsis) {
    /* Subtract four since after the selected page number, there are four page numbers displayed */
    pageDisplays = [pageNumberFirst, pageNumberEllipsis, ...range(pages - 4, pages)];
  }

  const pageNumberElements: React.ReactElement[] = pageDisplays.map((pageDisplay, index) => {
    const backStyle = pageDisplay === selectedPage + 1 ? selectedStyle : unselectedStyle;

    if (pageDisplay === pageNumberEllipsis) {
      return (
        <button key={index} className={pageButtonBaseStyle} disabled={true}>
          ...
        </button>
      );
    } else {
      return (
        <button
          key={index}
          onClick={() => pageChanged(pageDisplay - 1)}
          className={cx(pageButtonBaseStyle, backStyle)}
          disabled={pageDisplay === -1}
        >
          {pageDisplay}
        </button>
      );
    }
  });

  return (
    <div className={cx('flex justify-center mt-6', className)}>
      <Button
        type="button"
        variant="secondary"
        disabled={selectedPage === 0 || pages === 0}
        onClick={() => pageChanged(0)}
      >
        <DoubleChevronLeftIcon />
      </Button>
      <Button
        type="button"
        variant="secondary"
        disabled={selectedPage === 0 || pages === 0}
        onClick={() => pageChanged(Math.max(selectedPage - 1, 0))}
      >
        <ChevronLeftIcon />
      </Button>
      {pageNumberElements}
      <Button
        type="button"
        variant="secondary"
        disabled={selectedPage === pages - 1 || pages === 0}
        onClick={() => pageChanged(Math.min(selectedPage + 1, pages - 1))}
      >
        <ChevronRightIcon />
      </Button>
      <Button
        type="button"
        variant="secondary"
        disabled={selectedPage === pages - 1 || pages === 0}
        onClick={() => pageChanged(pages - 1)}
      >
        <DoubleChevronRightIcon />
      </Button>
    </div>
  );
};
