import cx from 'classnames';
import { Menu, Pane, Popover, Position } from 'evergreen-ui';
import { useRef } from 'react';

import More from '@/assets/more.png';

export type DropdownMenuItem<Entry> = {
  onClick: (entry: Entry) => void;
  label: string;
  disabled?: boolean;
  icon?: React.ReactElement;
};

type DropdownMenuProps<Entry> = {
  menuItems: DropdownMenuItem<Entry>[];
  entry: Entry;
  showBorder?: boolean;
  closeOnClick?: boolean;
};

export const DropdownMenu = <Entry extends { id?: string }>({
  menuItems,
  entry,
  showBorder,
  closeOnClick,
}: DropdownMenuProps<Entry>): JSX.Element => {
  const popoverRef = useRef<any>();

  return (
    <Popover
      // eslint-disable and ts-ignore are required because library type definition is not updated
      // eslint-disable-next-line
      // @ts-ignore
      ref={popoverRef}
      position={Position.BOTTOM_RIGHT}
      content={
        <Pane paddingY={8}>
          <Menu>
            {menuItems.map((menu, index) => (
              <Menu.Item
                onClick={() => {
                  menu.onClick(entry);
                  if (closeOnClick) {
                    popoverRef.current?.close();
                  }
                }}
                key={`${menu.label}-${index}`}
                disabled={menu.disabled}
                className="m-0 p-0"
              >
                <div
                  className={cx(
                    'flex items-center h-10 text-base mr-3',
                    menu.disabled ? 'text-neutral-500' : 'text-gray-500'
                  )}
                >
                  {menu.icon ? (
                    <span
                      className={cx(
                        'mr-4',
                        menu.disabled ? 'text-neutral-400' : 'text-neutral-600'
                      )}
                    >
                      {menu.icon}
                    </span>
                  ) : null}
                  {menu.label}
                </div>
              </Menu.Item>
            ))}
          </Menu>
        </Pane>
      }
    >
      <span
        className={cx(
          'w-10 h-10 rounded flex items-center justify-center hover:bg-neutral-200',
          showBorder && 'border border-neutral-400 round-3px'
        )}
      >
        <img src={More} alt="more actions" />
      </span>
    </Popover>
  );
};
