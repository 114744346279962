/**
 * A customized ReactRouter BrowserRouter that also tracks page transitions using google analytics.
 * The tutorials for react-router v4 and v5 suggest listening on the BrowserRouter's `history` object.
 * Since we're using react-router v6, it doesn't expose history. Don't know why. As a workaround, this
 * is a copy of react-router-dom's BrowserRouter, with an additional `trackingId` prop and the React.useEffect
 * recommended in: https://javascript.plainenglish.io/google-analytics-with-react-router-and-hooks-16d403ddc528
 */

import type { BrowserHistory } from 'history';
import { createBrowserHistory } from 'history';
import * as React from 'react';
import { Router } from 'react-router';

export interface BrowserRouterWithGAProps {
  basename?: string;
  children?: React.ReactNode;
  window?: Window;
  trackingId?: string | undefined;
}

/**
 * A <Router> for use in web browsers. Provides the cleanest URLs.
 */
export const BrowserRouterWithGA: React.FC<BrowserRouterWithGAProps> = ({
  basename,
  children,
  trackingId,
  window: passedWindow,
}) => {
  const historyRef = React.useRef<BrowserHistory>();
  if (historyRef.current === undefined) {
    historyRef.current = createBrowserHistory({ window: passedWindow || window });
  }

  const history = historyRef.current;
  const [state, setState] = React.useState({
    action: history.action,
    location: history.location,
  });

  React.useLayoutEffect(() => history.listen(setState), [history]);

  React.useEffect(() => {
    const unlisten = history.listen(({ location }) => {
      if (!window.gtag) return;
      if (!trackingId) {
        console.warn(
          'Tracking not enabled, as `trackingId` was not given and there is no `GA_MEASUREMENT_ID`.'
        );
        return;
      }

      // https://support.google.com/analytics/answer/9310895?hl=en#zippy=%2Cin-this-article
      window.gtag('event', trackingId, {
        page_path: location.pathname,
      });
    });

    return unlisten;
  }, [trackingId, history]);

  return (
    <Router basename={basename} location={state.location} navigator={history}>
      {children}
    </Router>
  );
};
