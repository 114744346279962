import { Transition } from '@headlessui/react';
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  XCircleIcon,
  InformationCircleIcon,
} from '@heroicons/react/outline';
import { XIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import { Fragment } from 'react';

import { BannerPriority, BannerType } from '@/hooks/useNotificationStore';

const icons: Record<BannerPriority, React.ReactElement> = {
  info: <InformationCircleIcon className="h-6 w-6 text-indigo-500" aria-hidden="true" />,
  success: <CheckCircleIcon className="h-6 w-6 text-indigo-500" aria-hidden="true" />,
  warning: <ExclamationCircleIcon className="h-6 w-6 text-yellow-500" aria-hidden="true" />,
  error: <XCircleIcon className="h-6 w-6 text-red-500" aria-hidden="true" />,
};

export type BannerProps = {
  banner: BannerType;
  onDismiss: (id: string) => void;
};

const PriorityColor: Record<BannerPriority, string> = {
  [BannerPriority.success]: 'green',
  [BannerPriority.info]: 'indigo',
  [BannerPriority.warning]: 'yellow',
  [BannerPriority.error]: 'red',
};

/*
 * A banner is a priority-based message that must be manually dismissed.
 */
export const Banner: React.FC<BannerProps> = ({
  banner: { id, message, priority, title },
  onDismiss,
}) => {
  const color = PriorityColor[priority];
  return (
    <>
      <div className="fixed w-full z-50" aria-live="assertive">
        <Transition
          show={true}
          as={Fragment}
          enter="transform ease-out duration-300 transition"
          enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
          enterTo="translate-y-0 opacity-100 sm:translate-x-0"
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div>
            <div
              className={classNames(
                'flex items-top justify-between max-w-md py-2 px-3 rounded-lg shadow-sm mx-auto mt-7 border',
                `bg-${color}-100 text-${color}-500 border-${color}-500`
              )}
              role="alert"
              aria-label={title}
            >
              <div className="flex-shrink-0">{icons[priority]}</div>
              <div className="w-full px-2 pt-0.5">
                <p className="font-bold">{title}</p>
                {message ? <p className="text-gray-500">{message}</p> : null}
              </div>
              <div className="flex-shrink-0">
                <button
                  className={classNames(
                    'rounded-lg inline-flex focus:outline-none focus:ring-1 focus:ring-offset-1',
                    `hover:text-${color}-800 focus:ring-${color}-500`
                  )}
                  onClick={() => {
                    onDismiss(id);
                  }}
                >
                  <span className="sr-only">Close</span>
                  <XIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </>
  );
};
