import kebabCase from 'lodash/kebabCase';

interface Props {
  title: string;
  body?: string | React.ReactElement;
  testId?: string;
}

export const StatusCard: React.FC<Props> = ({ title, body, testId }) => {
  return (
    <div
      className="h-[9.25rem] flex align-center justify-center flex-col text-center rounded border border-gray400 bg-white"
      data-testid={testId || kebabCase(title)}
    >
      <h4 className="text-sm uppercase mb-2 text-gray-700" data-testid="title">
        {title}
      </h4>
      <p className="text-2xl text-gray-900" data-testid="body">
        {body ?? <span>&nbsp;</span>}
      </p>
    </div>
  );
};
