/* Display a number representing seconds into a friendly duration of
   X min. Y sec. */
export const Duration: React.FC<{ duration?: number }> = ({ duration }) => {
  if (duration === undefined) {
    return <>--</>;
  }
  const value = Math.round(duration);
  const minutes = Math.floor(value / 60);
  const seconds = value % 60;
  if (!minutes) {
    return <>{`${seconds} sec.`}</>;
  }
  return <>{`${minutes} min. ${seconds} sec.`}</>;
};
