import Axios, { AxiosRequestConfig } from 'axios';

import { API_URL } from '@/config';

let token = '';
export function setAuth0AccessToken(newToken: string): void {
  token = newToken;
}

function authRequestInterceptor(config: AxiosRequestConfig): AxiosRequestConfig {
  if (config.headers) {
    if (token) {
      config.headers.authorization = `Bearer ${token}`;
    }
    config.headers['Content-Type'] = 'application/json';
    config.headers.Accept = 'application/json';
  }
  return config;
}

export const axios = Axios.create({
  baseURL: API_URL,
});

axios.interceptors.request.use(authRequestInterceptor);
axios.interceptors.response.use((response) => {
  return response.data;
});
