import { Spinner } from '@/components/Elements';

interface Props {
  value?: number;
  link?: string;
  onClick?: () => void;
}

export const CountLink: React.FC<Props> = ({ value, link, onClick }) => {
  if (typeof value === 'undefined') {
    return <Spinner size="sm" />;
  }

  if (onClick) {
    return (
      <span
        className="underline text-dojoyellow"
        role="button"
        tabIndex={-1}
        onKeyDown={() => {}}
        onClick={onClick}
      >
        {value}
      </span>
    );
  }

  return (
    <a target="_blank" rel="noreferrer" className="underline text-dojoyellow" href={link}>
      <span>{value}</span>
    </a>
  );
};
