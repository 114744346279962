import { DateTime } from 'luxon';
import React, { useMemo } from 'react';
import {
  Line,
  LineChart,
  Tooltip,
  Legend,
  YAxis,
  XAxis,
  Label,
  ResponsiveContainer,
} from 'recharts';
import { CategoricalChartState } from 'recharts/types/chart/generateCategoricalChart';

export const tooltipWrapperStyle: React.CSSProperties = {
  backgroundColor: '#FFFFFF',
  textAlign: 'left',
  verticalAlign: 'middle',
  borderRadius: 8,
  boxShadow: '0px 12px 40px rgba(16, 24, 64, 0.24)',
  padding: 12,
};

export type DataPoint = {
  createdAt: number;
  [key: string]: string | number;
};

export type LineChartLegend = {
  dataKey: string;
  label: string;
  color: string;
};

type EOLineChartProps = {
  dataPoints: DataPoint[];
  legends: LineChartLegend[];
  customToolTip?: JSX.Element;
  xAxisLabel: string;
  yAxisLabel: string;
  yDomainMin?: string | number;
  yDomainMax?: string | number;
  showTooltip?: boolean;
  formatYAxisTick?: (value: number) => string;
  onMouseMove?: (event: CategoricalChartState) => void;
};

export const EOLineChart: React.FC<EOLineChartProps> = ({
  dataPoints,
  legends,
  xAxisLabel,
  yAxisLabel,
  yDomainMin = 'dataMin',
  yDomainMax = 'dataMax',
  showTooltip = false,
  customToolTip,
  formatYAxisTick,
  onMouseMove,
}) => {
  const gap = useMemo(() => {
    const values = dataPoints.map((d) => Number(d.value));
    return Math.max(...values) - Math.min(...values);
  }, [dataPoints]);

  return (
    <ResponsiveContainer width={'100%'} height={280}>
      <LineChart
        data={dataPoints}
        margin={{ top: 0, right: 10, left: 28, bottom: 30 }}
        onMouseMove={onMouseMove}
      >
        <XAxis
          dataKey="createdAt"
          type="number"
          scale="utc"
          domain={['dataMin', 'dataMax']}
          interval="preserveStartEnd"
          angle={-15}
          tickFormatter={(timeStr) =>
            DateTime.fromMillis(parseInt(timeStr)).toLocaleString(DateTime.DATE_MED)
          }
          tickMargin={10}
          allowDataOverflow={false}
          tick={{
            fontSize: 14,
            fontWeight: 600,
            fill: 'rgba(0, 0, 0, 0.38',
          }}
        >
          <Label
            value={xAxisLabel}
            offset={17}
            position="bottom"
            style={{
              textAnchor: 'middle',
              fill: 'rgba(0, 0, 0, 0.38)',
              fontSize: 16,
              fontWeight: 600,
            }}
          />
        </XAxis>
        <YAxis
          name="Value"
          type="number"
          domain={[yDomainMin, yDomainMax]}
          interval="preserveStartEnd"
          scale="linear"
          tickFormatter={formatYAxisTick}
          tickCount={gap < 5 ? Math.max(1, gap) : undefined}
          allowDataOverflow={false}
          tick={{
            fontSize: 14,
            fontWeight: 600,
            fill: 'rgba(0, 0, 0, 0.38',
          }}
        >
          <Label
            value={yAxisLabel}
            position="left"
            angle={-90}
            offset={20}
            style={{
              textAnchor: 'middle',
              fill: 'rgba(0, 0, 0, 0.38)',
              fontSize: 16,
              fontWeight: 600,
            }}
          />
        </YAxis>
        {legends.map((legend) => (
          <Line
            key={legend.dataKey}
            name={legend.label}
            type="linear"
            strokeWidth={2}
            connectNulls={true}
            dot={{ strokeWidth: 2, r: 1, strokeDasharray: '' }}
            dataKey={legend.dataKey}
            stroke={legend.color}
          />
        ))}
        {showTooltip ? (
          <Tooltip content={customToolTip} wrapperStyle={tooltipWrapperStyle} />
        ) : (
          <Tooltip cursor={false} wrapperStyle={{ display: 'none' }} />
        )}
        <Legend
          align="right"
          verticalAlign="top"
          iconType="circle"
          iconSize={6}
          wrapperStyle={{
            fontSize: 14,
            fontWeight: 600,
            paddingBottom: 12,
          }}
          fontSize={14}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};
